//====================================================
//  Function: Validate forms
//====================================================

function componentValidateForms() {
  if ($('#form-contact').length) {
    $('#form-contact').each(function () {
      var formValidateContact = $(this)
      var form_data
      formValidateContact.parsley().on('form:submit', function () {
        form_data = formatFormResult(formValidateContact)
        $.ajax({
          type: 'POST',
          url: 'contact_form.php',
          data: form_data,
        }).done(function (response) {
          //to do show success message
          console.log(response)
          if (response === 'success') {
            $('.form-box').find('.js-hidden-content').removeClass(classIsActive)
            $('.form-box')
              .find('.js-hidden-content[data-content="form-contact-success"]')
              .addClass(classIsActive)
          } else {
            $('.form-box')
              .find('.js-hidden-content')
              .find('.beta')
              .html('Nepodarilo sa odoslať.')
            $('.form-box')
              .find('.js-hidden-content')
              .find('p:first-child')
              .html(
                "Napíšte nám správu priamo na <strong><a href='mailto:klemanoval@gmail.com'>klemanoval@gmail.com</a></strong>.",
              )
            $('.form-box').find('.js-hidden-content').removeClass(classIsActive)
            $('.form-box')
              .find('.js-hidden-content[data-content="form-contact-success"]')
              .addClass(classIsActive)
          }
        })
        return false
      })
    })

    var servicesArray = []
    $('.js-checkbox-input').change(function () {
      var inst = $(this)
      var servicesArray = []
      $('.js-checkbox-input').each(function () {
        var elem = $(this)
        if (elem.is(':checked')) {
          var text = elem.next().find('.checkbox__text').text()
          servicesArray.push(text)
        }
      })
      $('#contact_services').val(servicesArray.join(', '))
    })
  }

  window.Parsley.on('field:error', function () {
    $(this.$element).closest('.js-field-validation').addClass('has-field-error')
    $(this.$element)
      .closest('.js-field-validation')
      .removeClass('has-field-success')
  })

  window.Parsley.on('field:success', function () {
    $(this.$element)
      .closest('.js-field-validation')
      .removeClass('has-field-error')
    $(this.$element)
      .closest('.js-field-validation')
      .addClass('has-field-success')
  })

  function formatFormResult(form) {
    var unindexed_array = form.serializeArray()
    var indexed_array = {}

    $.map(unindexed_array, function (n, i) {
      indexed_array[n['name']] = n['value']
    })

    console.log(form.serialize())

    return form.serialize()
  }
}
