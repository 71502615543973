//====================================================
//  Function: Parallax
//====================================================
function componentParallax() {
  if ($('.js-parallax').length) {
    var parallaxImages = document.querySelectorAll('.js-parallax')
    new simpleParallax(parallaxImages, {
      delay: 0.8,
      orientation: 'down',
      scale: 1.05,
    })
  }

  if ($('.js-parallax-down').length) {
    var parallaxImages = document.querySelectorAll('.js-parallax-down')
    new simpleParallax(parallaxImages, {
      delay: 0.8,
      orientation: 'up',
      scale: 1.1,
    })
  }

  if ($('.js-parallax-small').length) {
    var parallaxImages = document.querySelectorAll('.js-parallax-small')
    new simpleParallax(parallaxImages, {
      delay: 0.8,
      orientation: 'down',
      scale: 1.05,
    })
  }
}
